import { Button, Typography, Grid, Container } from "@material-ui/core";
import Link from "next/link";
import * as React from "react";
import { createStyles, makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(() =>
  createStyles({
    mainTitleStyle: {
      fontSize: 45,
      marginBottom: 0,
      fontFamily: 'sans-serif',
      fontWeight: 'normal',
    },
    errorTextStyle: {
      fontSize: 90,
      fontFamily: 'fantasy',
      letterSpacing: 8
    },
    textLightBlack: {
      color: '#858585',
      fontFamily: 'sans-serif',
    },
    mt20: {
      marginTop: 20,
    },
    mt45: {
      marginTop: 45,
    },
    pt15: {
      paddingTop: '15%',
    },
    textCenter: {
      textAlign: 'center'
    },
  }),
);

function NotFound() {
  const classes = useStyles();
  return (
    <Container maxWidth="md" className={classes.pt15}>
      <Grid container spacing={3} alignContent="center" alignItems="center">
        <Grid item xs={12} md={8}>
          <Typography variant="h1"
                      className={classes.mainTitleStyle}>Page Not
            Found</Typography>
          <Typography variant="subtitle2" className={classes.textLightBlack}>The link you clicked may be broken or the
            page may have been
            removed.
          </Typography>
          <Link href="/">
            <Button
              variant="contained"
              className={classes.mt20}
              size="small"
              color="primary"
            >
              Go Back To Home
            </Button>
          </Link>
        </Grid>
        <Grid item xs={12} md={4} className={classes.textCenter + " " + classes.mt45}>
          <Typography variant="subtitle1" color="primary" component="strong"
                      className={classes.errorTextStyle}> 404 </Typography>
        </Grid>
      </Grid>
    </Container>
  )
}

export default NotFound;
